import './App.css';
import HomeScreen from './screens/HomeScreen';
import ChatScreen from './screens/ChatScreen';
import RankedScreen from './screens/RankedScreen';
import React, { useState, useEffect } from 'react';
import logoTeamupp from './assets/LogoTeamupp.png';
import ChallengeScreen from './screens/ChallengeScreen';
import EntrepriseScreen from './screens/EntrepriseScreen';
import { BrowserRouter as Router, Link, Route, Routes, useLocation } from 'react-router-dom';

import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUpWideShort,
    faBuildings,
    faCommentLines,
    faFlag,
    faHouseBlank,
    faFileInvoice
} from '@fortawesome/pro-regular-svg-icons';
import LoginScreen from './screens/LoginScreen';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import BuyScreen from './screens/BuyScreen';
import CustomerForm from './screens/CustomerFormScreen';
import PaymentMethods from './screens/PaymentMethod';
import PayInvoice from './screens/PayInvoice';

import { useTranslation } from 'react-i18next';
import i18n from './services/i118n';
import UserService from './services/UserService';

import dpEnglish from './assets/english.jpg';
import dpFrench from './assets/dp-fr.jpg';
import { changeLanguage } from 'i18next';


function App() {
    const [token, setToken] = useState('');
    const [activeLink, setActiveLink] = useState('Accueil');
    const [isOpen, setIsOpen] = useState(false);
    const { getItem } = useAsyncStorage('token');
    const { getItem: getLanguage, setItem: setLanguage } = useAsyncStorage('language');
    const [user, setUser] = useState<any>();
    

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    setToken(savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();
        const interval = setInterval(getToken, 100);
        return () => clearInterval(interval);
    }, [getItem]);

    useEffect(() => {
        if (!token) return;

        UserService.getUser(token).then(async (data: any) => {
            setUser(data);
            console.log('user language :', data.language);
            await setLanguage(data.language);
        }).catch((error: any) => {
            console.error('Error fetching challenges:', error);
        });
    }, [token]);

    // useEffect(() => {
    //     const loadLanguageSetting = async () => {
    //       try {
    //         const language = await getLanguage();
    //         console.log('language :', language);
    //         if (language) {
    //           i18n.changeLanguage(language);
    //         }
    //       } catch (error) {
    //         console.error('Erreur lors du chargement de la langue préférée:', error);
    //       }
    //     };
    
    //     loadLanguageSetting();
    //   }, [getLanguage, user]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Router>
            {token ? (
                <div>
                    <NavBar activeLink={activeLink} setActiveLink={setActiveLink} />
                    <MobileNavBar isOpen={isOpen} handleToggle={handleToggle} />
                    <Routes>
                        <Route path="/" element={<HomeScreen />} />
                        <Route path="/challenge" element={<ChallengeScreen />} />
                        <Route path="/classement" element={<RankedScreen />} />
                        <Route path="/chat" element={<ChatScreen />} />
                        <Route path="/entreprise" element={<EntrepriseScreen />} />
                        <Route path="/buy" element={<BuyScreen />} />
                        <Route path="/customer" element={<CustomerForm />} />
                        <Route path="/method" element={<PaymentMethods />} />
                        <Route path="/pay" element={<PayInvoice />} />

                    </Routes>
                </div>
            ) : (
                <LoginScreen />
            )}
        </Router>
    );
}

function NavBar({ activeLink, setActiveLink }: { activeLink: string, setActiveLink: React.Dispatch<React.SetStateAction<string>> }) {
    const location = useLocation();
    const { t } = useTranslation();
    const [token, setToken] = useState('');
    const { getItem } = useAsyncStorage('token');
    const { getItem: getLanguage, setItem: setLanguage } = useAsyncStorage('language');
    const [user, setUser] = useState<any>();

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    setToken(savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();
        const interval = setInterval(getToken, 100);
        return () => clearInterval(interval);
    }, [getItem]);

    useEffect(() => {
        if (!token) return;

        UserService.getUser(token).then(async (data: any) => {
            setUser(data);
            console.log('user language :', data.language);
            await setLanguage(data.language);
        }).catch((error: any) => {
            console.error('Error fetching challenges:', error);
        });
    }, [token]);

    // useEffect(() => {
    //     const loadLanguageSetting = async () => {
    //       try {
    //         const language = await getLanguage();
    //         if (language) {
    //           i18n.changeLanguage(language);
    //         }
    //       } catch (error) {
    //         console.error('Erreur lors du chargement de la langue préférée:', error);
    //       }
    //     };
    
    //     loadLanguageSetting();
    //   }, [getLanguage, user]);
    
    const changeLanguageFunction = async (language: string) => {
        const updateUser = {
            ...user,
            language : language
        }
        console.log(updateUser);
        const updateLanguageUser = await UserService.updateUser(token, updateUser);
        console.log('updateLanguageUser :', updateLanguageUser);
        await setLanguage(language);
        i18n.changeLanguage(language);
    };

    
    useEffect(() => {
        const currentPath = location.pathname;
        switch (currentPath) {
            case '/':
                setActiveLink('Accueil');
                break;
            case '/challenge':
                setActiveLink('Challenge');
                break;
            case '/classement':
                setActiveLink('classement');
                break;
            case '/chat':
                setActiveLink('chat');
                break;
            case '/entreprise':
                setActiveLink('Entreprise');
                break;
            case '/pay':
                setActiveLink('Paiements');
                break;
            default:
                setActiveLink('');
        }
    }, [location.pathname, setActiveLink]);

    return (
        <nav className='navBarConainer'>
            <div>
                <img src={logoTeamupp} className="logoTeamupp" alt="logo" />
            </div>
            <div className='flag-container'>
                <a style={{cursor:"pointer"}} onClick={()=>{changeLanguageFunction("fr")}}><img src={dpFrench} alt="" className='dp-img' /></a>
                <a style={{cursor:"pointer"}} onClick={()=>{changeLanguageFunction("en")}}><img src={dpEnglish} className='dp-img' alt="" /></a>
            </div>
            <Link to="/" onClick={() => setActiveLink('Accueil')} style={{ textDecoration: "none" }}>
                <div className={activeLink === 'Accueil' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                    <FontAwesomeIcon icon={faHouseBlank} size='xl' className="logoItem" />
                    <h3 className='titleItemNavBar'>{t("home")}</h3>
                </div>
            </Link>
            <Link to="/challenge" onClick={() => setActiveLink('Challenge')} style={{ textDecoration: "none" }}>
                <div className={activeLink === 'Challenge' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                    <FontAwesomeIcon icon={faFlag} size='xl' className="logoItem" />
                    <h3 className='titleItemNavBar'>{t("challenges")}</h3>
                </div>
            </Link>
            <Link to="/classement" onClick={() => setActiveLink('classement')} style={{ textDecoration: "none" }}>
                <div className={activeLink === 'classement' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                    <FontAwesomeIcon icon={faArrowUpWideShort} size='xl' className="logoItem" />
                    <h3 className='titleItemNavBar'>{t("classement")}</h3>
                </div>
            </Link>
            <Link to="/chat" onClick={() => setActiveLink('chat')} style={{ textDecoration: "none" }}>
                <div className={activeLink === 'chat' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                    <FontAwesomeIcon icon={faCommentLines} size='xl' className="logoItem" />
                    <h3 className='titleItemNavBar'>{t("chat")}</h3>
                </div>
            </Link>
            <Link to="/entreprise" onClick={() => setActiveLink('Entreprise')} style={{ textDecoration: "none" }}>
                <div className={activeLink === 'Entreprise' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                    <FontAwesomeIcon icon={faBuildings} size='xl' className="logoItem" />
                    <h3 className='titleItemNavBar'>{t("entreprise")}</h3>
                </div>
            </Link>
            <Link to="/pay" onClick={() => setActiveLink('Paiements')} style={{ textDecoration: "none" }}>
                <div className={activeLink === 'Paiements' ? 'activeItemNavBarContainer' : 'itemNavBarContainer'}>
                    <FontAwesomeIcon icon={faFileInvoice} size='xl' className="logoItem" />
                    <h3 className='titleItemNavBar'>Paiements</h3>
                </div>
            </Link> 
        </nav>
    );
}

function MobileNavBar({ isOpen, handleToggle }: { isOpen: boolean, handleToggle: () => void }) {
    const { t } = useTranslation();
    return (
        <nav className='navBar-phone'>
            <div className='nav-phone-container'>
                <img src={logoTeamupp} alt="logo" className='img-phone' />
                <FontAwesomeIcon icon={faBars} size='1x' className='icon-phone' onClick={handleToggle} />
            </div>
            {isOpen && (
                <div className="navbar">
                    <ul className="navbar-list">
                        <li className="navbar-item">
                            <Link to="/" className="navbar-link" onClick={handleToggle}>{t("home")}</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/challenge" className="navbar-link" onClick={handleToggle}>{t("challenges")}</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/chat" className="navbar-link" onClick={handleToggle}>{t("chat")}</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/classement" className="navbar-link" onClick={handleToggle}>{t("classement")}</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/entreprise" className="navbar-link" onClick={handleToggle}>{t("entreprise")}</Link>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
}

export default App;
