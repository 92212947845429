import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    setFunction: (type: string) => void;
    activeState: string;
    states: {
        name: string;
        value: string;
    }[];
    name: string;
    reload?: boolean;
};

export default function FilterBar({
    setFunction,
    activeState,
    states,
    name,
    reload = false,
}: Props) {
    const { t } = useTranslation();

    return (
        <div className="stats-date-container item-container">
            <div className="stat-date-title">
                <h3>{t(name)}</h3>
                <a className="refresh-icon-responsive">
                    <div className="">
                        <FontAwesomeIcon
                            icon={faArrowsRotate}
                            size="xl"
                            className="ellipse-white-top"
                        />
                    </div>
                </a>
            </div>

            <div className="left-date-link-container">
                <div className={"date-link-container-selected"}>
                    {states.map((state) => (
                        <a
                            href="#"
                            onClick={() => setFunction(state.value)}
                            className={
                                activeState === state.value
                                    ? "selected-link"
                                    : "link"
                            }
                            key={state.value}
                        >
                            {t(state.name)}
                        </a>
                    ))}
                </div>
                {reload && (
                    <a href="">
                        <div className="refresh-icon">
                            <FontAwesomeIcon
                                icon={faArrowsRotate}
                                size="xl"
                                className="ellipse-white-top"
                            />
                        </div>
                    </a>
                )}
            </div>
        </div>
    );
}
