import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en/translation.json';
import fr from '../locale/fr/translation.json';

i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: {
      en: { translation: en },
      fr: { translation: fr },
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
