import Header from "../components/Header";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { useAsyncStorage } from "@react-native-async-storage/async-storage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAward,
    faAwardSimple,
    faTrophy,
} from "@fortawesome/pro-regular-svg-icons";

import { IUser } from "../interfaces/IUsers";
import userPicture from "../assets/img-user.png";
import { ICompany } from "../interfaces/ICompany";
import EntrepriseService from "../services/EntrepriseService";

import {
    faArrowsRotate,
    faShoePrints,
    faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { IAgenda } from "../interfaces/IAgenda";
import { PropagateLoader } from "react-spinners";
import FilterBar from "../components/FilterBar";
import { useTranslation } from "react-i18next";

const defaultProfilePicture =
    "https://res.cloudinary.com/dr2opzcia/image/upload/v1679595811/profilepic_kyzsdc.jpg";

let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const RankedScreen: React.FC = () => {
    const [token, setToken] = useState("");
    const [users, setUsers] = useState<any>([]);
    const { getItem } = useAsyncStorage("token");
    const [company, setCompany] = useState<ICompany | null>(null);
    const [agendaType, setAgendaType] = useState("");
    const [period, setPeriod] = useState<string>("thisMonth");
    const [method, setMethod] = useState<string>("sum");
    const [gameType, setGameType] = useState<string>("team");
    const [annualAgendas, setAnnualAgendas] = useState([]);
    const [classicalAgendas, setClassicalAgendas] = useState([]);
    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const isPhone = useMediaQuery({ maxWidth: 767 });
    const {t} = useTranslation();

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    setToken(savedToken);
                    console.log("Token loaded :", savedToken);
                }
            } catch (error) {
                console.error("Error loading token from AsyncStorage:", error);
            }
        };

        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;

        EntrepriseService.getCompany(token)
            .then((data: any) => {
                setCompany(data);
                setLoading(false);
            })
            .catch((error: any) => {
                console.error("Error fetching challenges:", error);
            });
    }, [token]);


    useEffect(() => {
        if (!token || token == undefined || !company || company == undefined)
            return;

        EntrepriseService.getCompanyAgendas(token, company._id).then(
            (agendas: any) => {
                const classical = agendas.filter(
                    (agenda: any) => agenda.type === "Classical"
                );
                console.log("Classical:", classical);
                setClassicalAgendas(classical);
                setLoading(false);
                const annual = agendas.filter(
                    (agenda: any) => agenda.type === "Annual"
                );
                setAnnualAgendas(annual);
                console.log("Annual:", annual);
                setLoading(false);
            }
        );
    }, [token, company]);

    useEffect(() => {
        if (classicalAgendas.length > 0) {
            setAgendaType("Classical");
        } else if (annualAgendas.length > 0) {
            setAgendaType("Annual");
        }
    }, [annualAgendas, classicalAgendas]);

    const fetchData = async (agenda: any) => {
        console.log("agenda._id", agenda?._id, agendaType);
        if (!token || !company || !agenda) return;
        EntrepriseService.getCompanyRanking(
            token,
            gameType,
            period,
            method,
            agenda?._id,
            agenda?.type
        ).then((users) => {
            setUsers(users);
            setLoading(false);
        });
    };


    useEffect(() => {
        console.log("agendaType", agendaType);
        agendaType === "Annual"
            ? fetchData(annualAgendas[0])
            : fetchData(classicalAgendas[0]);
    }, [agendaType, gameType, period, method]);

    const handlesetGameType = (type: string) => {
        return company?.isOnlyTeamGame ? null : setGameType(type);
    };

    const handleSetAgendaType = (type: string) => {
        if (type === "Classical" && classicalAgendas.length > 0)
            return setAgendaType(type);
        if (type === "Annual" && annualAgendas.length > 0)
            return setAgendaType(type);
    };

    if (loading) {
        return (
            <div className="container-loading">
                <PropagateLoader color="#4F12B8" />
            </div>
        );
    }

    const handleRefresh = () => {
        setRefresh(!refresh);
    }

    return (
        <div className="container">
            <Header title={t("classement")} />
            <FilterBar
                setFunction={handleSetAgendaType}
                activeState={agendaType}
                states={[
                    { name: "annuel", value: "Annual" },
                    { name: "classique", value: "Classical" },
                ]}
                name="ranger-ordre-agenda"
            />
            <FilterBar
                setFunction={handlesetGameType}
                activeState={gameType}
                states={[
                    { name: "individual", value: "individual" },
                    { name: "equipe", value: "team" },
                ]}
                name="filtrer-classement"
            />
            <FilterBar
                setFunction={setPeriod}
                activeState={period}
                states={[
                    { name: "Cette semaine", value: "thisWeek" },
                    { name: "ce-mois", value: "thisMonth" },
                    { name: "mois-dernier", value: "lastMonth" },
                ]}
                name="stats-periode"
            />
            {gameType === "team" && (
                <FilterBar
                    setFunction={setMethod}
                    activeState={method}
                    states={[
                        { name: "total-points", value: "sum" },
                        { name: "moyenne", value: "avg" },
                    ]}
                    name="calcule-points"
                />

            )}

            {users.length === 0 || loading ? (
                <div className="container-loading">
                    <PropagateLoader color="#4F12B8" />
                </div>
            ) : (
                <div className="item-container">
                    {!isPhone && (
                        <div className="top-rank-list-container">
                            {users.length >= 2 &&
                                [users[1], users[0], ...users.slice(2, 3)].map(
                                    (person: any, index: number) => {
                                        const adjustedIndex =
                                            index === 0
                                                ? 1
                                                : index === 1
                                                ? 0
                                                : 2;
                                        return (
                                            <div
                                                className="top-rank-container"
                                                key={person.rank}
                                            >
                                                <div>
                                                    <img
                                                        src={
                                                            person.picture && !person.picture.endsWith('.heic')
                                                            ? `${BASE_URL}/${person.picture}`
                                                            : `${BASE_URL}/uploads/profile/random2.png`
                                                        }
                                                        alt={person.name}
                                                        className="pitcure-users-list display-none"
                                                    />
                                                    <div className="user-icon">
                                                        {adjustedIndex ===
                                                            0 && (
                                                            <FontAwesomeIcon
                                                                icon={faTrophy}
                                                                size="lg"
                                                            />
                                                        )}
                                                        {adjustedIndex ===
                                                            1 && (
                                                            <FontAwesomeIcon
                                                                icon={faAward}
                                                                size="lg"
                                                            />
                                                        )}
                                                        {adjustedIndex ===
                                                            2 && (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faAwardSimple
                                                                }
                                                                size="lg"
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                <div
                                                    className={`user-info-container top${
                                                        adjustedIndex + 1
                                                    }`}
                                                >
                                                    <h2
                                                        className={`top${
                                                            adjustedIndex + 1
                                                        }-text`}
                                                    >
                                                        {person.name}
                                                    </h2>
                                                    <p
                                                        className={`top${
                                                            adjustedIndex + 1
                                                        }-text`}
                                                    >
                                                        {person.score} {t("pts")}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    )}

                    <div />

                    <div className="rank-users-container">
                        <ul className="rank-users-list-container">
                            {users.map((person: any) => (
                                <li className="info-users" key={person.rank}>
                                    <div className="user-name">
                                        <p>{person.rank}</p>
                                        <img
                                            src={
                                                person.picture && !person.picture.endsWith('.heic')
                                                ? `${BASE_URL}/${person.picture}`
                                                : `${BASE_URL}/uploads/profile/random2.png`
                                            }
                                            alt={person.name}
                                            className="pitcure-users-list display-none"
                                            />
                                        <p>{person.name}</p>
                                    </div>
                                    <div className="users-point-container">
                                        <p>{person.score} {t("pts")}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RankedScreen;
