import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import React, { useState, useEffect } from 'react';
import '../CustomerForm.css';  // Importer le fichier CSS
import EntrepriseService from '../services/EntrepriseService';
import UserService from '../services/UserService';

const CustomerForm = ({  }) => {
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    address: {
      line1: '',
      city: '',
      postal_code: '',
      country: 'FR',
    },
    phone: '',
    taxId: '',
  });
  const [token, setToken] = useState('');
  const { getItem } = useAsyncStorage('token');

  
  useEffect(() => {
    const getToken = async () => {
        try {
            const savedToken = await getItem();
            if (savedToken !== null) {
                setToken(savedToken);
                console.log('Token loaded :', savedToken);
            }
        } catch (error) {
            console.error('Error loading token from AsyncStorage:', error);
        }
    };

    getToken();
}, []);

  useEffect(() => {
    // Charger les informations actuelles du client depuis l'API backend
    if(!token) return;
     UserService.getStripeInfo(token).then((data) => {
      if(data) {
        setCustomerInfo({
          name: data.name,
          address: {
            line1: data.address.line1,
            city: data.address.city,
            postal_code: data.address.postal_code,
            country: data.address.country,
          },
          phone: data.phone,
          taxId: data.taxId,
        });
      }

    }); 
  }, [token]);

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    setCustomerInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddressChange = (e:any) => {
    const { name, value } = e.target;
    setCustomerInfo((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    // Sauvegarder les informations mises à jour
    UserService.updateStripeInfo(token, customerInfo).then((response) => {
      if (response.id) {
        alert('Informations mises à jour avec succès !');
      } else {
        alert('Erreur lors de la mise à jour.');
      }
    }); 
  };

  return (
    <div className="form-container">
      <h2>Adresse de facturation</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nom entreprise</label>
          <input
            type="text"
            name="name"
            value={customerInfo?.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Adresse</label>
          <input
            type="text"
            name="line1"
            value={customerInfo?.address.line1}
            onChange={handleAddressChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Ville</label>
          <input
            type="text"
            name="city"
            value={customerInfo?.address.city}
            onChange={handleAddressChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Code postal</label>
          <input
            type="text"
            name="postal_code"
            value={customerInfo?.address.postal_code}
            onChange={handleAddressChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Pays</label>
          <select
            name="country"
            value={customerInfo?.address.country}
            onChange={handleAddressChange}
          >
            <option value="FR">France</option>
            <option value="BE">Belgique</option>
            {/* Ajouter d'autres pays si nécessaire */}
          </select>
        </div>

        <div className="form-group">
          <label>Numéro de TVA</label>
          <input
            type="text"
            name="taxId"
            value={customerInfo.taxId}  // Affiche le numéro de TVA existant
            onChange={handleInputChange}
          />
        </div>

        <div className="form-footer">
          <button type="submit">Sauvegarder</button>
        </div>
      </form>
    </div>
  );
};

export default CustomerForm;