import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import React, { useEffect, useState } from 'react';
import PaymentService from '../services/PaymentService';
import '../BuyScreen.css';
import UserService from '../services/UserService';

export enum PaymentStatus {
  Unpaid = 'unpaid',
  Paid = 'paid',
  Failed = 'failed',
  Processing = 'processing',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

const statusMap: Record<PaymentStatus, string> = {
  [PaymentStatus.Unpaid]: 'Non payé',
  [PaymentStatus.Paid]: 'Payé',
  [PaymentStatus.Processing]: 'En cours',
  [PaymentStatus.Failed]: 'Échoué',
  [PaymentStatus.Pending]: 'En attente',
  [PaymentStatus.Cancelled]: 'Annulé',
};

const BuyScreen = () => {
  const [payments, setPayments] = useState<any[]>([]);
  const [loadingPaymentId, setLoadingPaymentId] = useState<string | null>(null);
  const [paymentMethodsExist, setPaymentMethodsExist] = useState(false);
  const [token, setToken] = useState<string>("");
  const { getItem } = useAsyncStorage('token');
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      try {
        const savedToken = await getItem();
        if (savedToken !== null) {
          setToken(savedToken);
        }
      } catch (error) {
        console.error('Error loading token from AsyncStorage:', error);
      }
    };
    getToken();
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        if (!token) return;
        const response = await PaymentService.getPayments(token);
        setPayments(response || []);
        
        const paymentMethodsResponse = await UserService.getPaymentMethods(token);
        const methods = paymentMethodsResponse?.paymentMethods || [];
        setPaymentMethodsExist(methods.length > 0);
        // Vérifier si un moyen de paiement par défaut est défini
        console.log(paymentMethodsResponse)
        setHasDefaultPaymentMethod(paymentMethodsResponse.defaultPaymentMethodId != null);
      } catch (error) {
        console.error('Erreur lors du chargement des paiements', error);
      }
    };

    if (token) {
      fetchPayments();
    }
  }, [token]);

  const handlePayment = async (paymentId: string) => {
    try {
      setLoadingPaymentId(paymentId);

      const response = await PaymentService.payPayment(token, paymentId);

      if (response.success) {
        alert('Paiement réussi !');
        // Mettre à jour le statut du paiement dans la liste
        setPayments((prevPayments) =>
          prevPayments.map((payment) =>
            payment._id === paymentId ? { ...payment, status: PaymentStatus.Paid } : payment
          )
        );
      } else if (response.payment && response.payment.status === 'processing') {
        alert('Le paiement est en cours de traitement. Veuillez patienter quelques jours.');
        // Mettre à jour le statut du paiement dans la liste
        setPayments((prevPayments) =>
          prevPayments.map((payment) =>
            payment._id === paymentId ? { ...payment, status: 'processing' } : payment
          )
        );
      } else {
        alert('Erreur lors du paiement.');
      }
    } catch (error) {
      alert('Une erreur est survenue lors du paiement.');
      console.error(error);
    } finally {
      setLoadingPaymentId(null);
    }
  };

  return (
    <div className="buy-screen">
    <h2>Mes Paiements</h2>

    {payments.length === 0 ? (
      <p>Aucun paiement en cours pour le moment.</p>
    ) : (
      <table>
        <thead>
          <tr>
            <th>Nom du Défi</th>
            <th>Montant HT</th>
            <th>Montant TTC</th>
            <th>Statut</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => {
            const statusKey = payment.status as PaymentStatus;
            return (
              <tr key={payment._id}>
                <td>{payment.description}</td>
                <td>{payment.value} EUR</td>
                <td>{payment.totalAmountWithTax} EUR</td>
                <td>{statusMap[statusKey]}</td>
                <td>
                  {(payment.status === PaymentStatus.Unpaid || payment.status === PaymentStatus.Pending) ? (
                    paymentMethodsExist ? (
                      hasDefaultPaymentMethod ? (
                        <button
                          onClick={() => handlePayment(payment._id)}
                          disabled={loadingPaymentId === payment._id}
                        >
                          {loadingPaymentId === payment._id ? 'Paiement en cours...' : 'Payer'}
                        </button>
                      ) : (
                        <button disabled>Ajoutez un paiement par défaut</button>
                      )
                    ) : (
                      <button disabled>Ajoutez un moyen de paiement</button>
                    )
                  ) : payment.status === PaymentStatus.Paid ? (
                    <>
                    {payment.invoiceUrl && (
                      <a href={payment.invoiceUrl} target="_blank" rel="noopener noreferrer">
                        <button>Télécharger la facture</button>
                      </a>
                    )}
                  </>
                  ) : payment.status === PaymentStatus.Processing ? (
                    <span>Paiement en cours</span>
                  ) : (
                    <span>Statut inconnu</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    )}
  </div>
  );
};

export default BuyScreen;
