import React, { useState, useEffect } from 'react';
import '../Payment.css';  // Importer le fichier CSS pour styliser
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import UserService from '../services/UserService';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import SetupForm from '../components/SetupForm'; // Composant pour ajouter un moyen de paiement

// Charger Stripe avec la clé publique
const stripePromise = loadStripe('pk_test_51QCOShE3FqbmnusHOlkUbxUHNiE4z8iqWb7RiOzBKUfzKnlBui0TKAD3eUsGrvvaupuGNcX6YaIGWdtNBeWiJqeV00MqI6YU4u');

const PaymentMethods = () => {
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('');
    const [clientSecret, setClientSecret] = useState<string | undefined>();
    const [token, setToken] = useState('');
    const { getItem } = useAsyncStorage('token');
    const [termsAccepted, setTermsAccepted] = useState(false);  // État pour suivre si les conditions sont acceptées
    const [customerIdExists, setCustomerIdExists] = useState(false);

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    setToken(savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };
        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;
        UserService.getUser(token).then((data) => {
            setCustomerIdExists(!!data.customerId);  // Vérifie si le customerId existe
        })

        handleAddPaymentMethod()
        //handleSetupSuccess()
    }, [token]);

    useEffect(() => {
        if (!token) return;
        UserService.getPaymentMethods(token).then((data: any) => {
            setPaymentMethods(data.paymentMethods); // Récupère les méthodes de paiement
            setDefaultPaymentMethod(data.defaultPaymentMethodId); // Définir la méthode par défaut
        });
    }, [token]);

    const handleDeletePaymentMethod = async (paymentMethodId: string) => {
        await UserService.deletePaymentMethod(token, paymentMethodId);
        // Mise à jour des méthodes de paiement après suppression
        const updatedPaymentMethods = paymentMethods.filter(method => method.id !== paymentMethodId);
        setPaymentMethods(updatedPaymentMethods);
        if (defaultPaymentMethod === paymentMethodId) {
            setDefaultPaymentMethod('');
        }
    };

    const handleSetDefaultPaymentMethod = async (paymentMethodId: string) => {
        try {
            await UserService.setDefaultPaymentMethod(token, paymentMethodId);
            setDefaultPaymentMethod(paymentMethodId);
        } catch (error) {
            console.error('Erreur lors de la définition de la méthode de paiement par défaut:', error);
        }
    };

    const handleAddPaymentMethod = async () => {
        // Créer un SetupIntent pour ajouter une nouvelle méthode de paiement
        const response = await UserService.createSetupIntent(token);
        console.log(response)
        setClientSecret(response);
    };


    const loadPaymentMethods = async () => {
        try {
            const data = await UserService.getPaymentMethods(token);
            setPaymentMethods(data.paymentMethods);
            setDefaultPaymentMethod(data.defaultPaymentMethodId);
        } catch (error) {
            console.error('Erreur lors du chargement des méthodes de paiement:', error);
        }
    };


    const handleSetupSuccess = async () => {
        // Recharger les méthodes de paiement après ajout d'une nouvelle méthode
        await loadPaymentMethods();
    /*     await handleSetDefaultPaymentMethod(paymentMethods[0].id);
        // Si aucune méthode n'était par défaut avant, définir la nouvelle méthode comme par défaut
        if (paymentMethods.length === 0 && paymentMethods[0]?.id) {
            await handleSetDefaultPaymentMethod(paymentMethods[0].id);
        } */

        setClientSecret(undefined);
    };

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#303238',
            colorDanger: '#df1b41',
            fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
            spacingUnit: '4px',
            borderRadius: '8px',
        },
        rules: {
            '.Input': {
                borderColor: '#cccccc',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
            },
            '.Label': {
                color: '#6b7c93',
            },
        },
    };

    const options: any = clientSecret ? { clientSecret, appearance } : undefined;

    return (
        <div className="form-container">

            <h2>Moyen de paiement actuel</h2>
            <ul className="payment-method-list">
                {paymentMethods?.map((method: any) => (
                    <li key={method.id} className="payment-method-item">
                        <div className="payment-method-details">
                            {method.card ? (
                                <>
                                    <span>{method.card.brand.toUpperCase()}</span>
                                    <span>**** **** **** {method.card.last4}</span>
                                </>
                            ) : (
                                <>
                                    <span>Prélèvement SEPA</span>
                                    <span>**** {method.sepa_debit.last4}</span>
                                </>
                            )}
                        </div>
                        {/*{method.id === defaultPaymentMethod && <span className="default-badge">Défaut</span>}*/}
                        <div className="payment-method-actions">
                            <button onClick={() => handleSetDefaultPaymentMethod(method.id)} disabled={method.id === defaultPaymentMethod}>
                                Définir comme défaut
                            </button>
                            <button onClick={() => handleDeletePaymentMethod(method.id)}>
                                Supprimer
                            </button>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Formulaire pour ajouter une nouvelle méthode de paiement */}
            <div className="add-payment-method">
                <h3>Ajouter un nouveau moyen de paiement</h3>

                {!customerIdExists ? (
                <p>Pour ajouter un paiement, mettez à jour vos informations de facturation.</p>
                ) : (
                    <>
                        {clientSecret ? (
                            <Elements stripe={stripePromise} options={options}>
                                <SetupForm onSuccess={handleSetupSuccess} />
                            </Elements>
                        ) : (null)}
                    </>
                )}


            </div>
        </div>
    );
};

export default PaymentMethods;
