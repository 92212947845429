import Header from '../components/Header';
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';
import { faClock, faArrowDownRight, faArrowUpRight, faAward, faBullseyeArrow, faFlag, faPenField } from '@fortawesome/pro-regular-svg-icons';
import AliceCarousel from 'react-alice-carousel';
import BarVerticalChart from '../components/BarChartVertical';
import backgroundCard from '../assets/background-card-challenge.png';
import InterpolationModesChart from '../components/InterpolationModesChart';

import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import EntrepriseService from '../services/EntrepriseService';
import { PropagateLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const ChallengeScreen: React.FC = () => {
    const [token, setToken] = useState('');
    const [stats, setStats] = useState<any>();
    const { getItem } = useAsyncStorage('token');
    const [selectedLink, setSelectedLink] = useState(0);
    const [messagesByDay, setMessagesByDay] = useState<any[]>([]);
    const [challenge, setChallenge] = useState<any[]>([]);

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();

                if (savedToken !== null) {
                    setToken(savedToken);
                    console.log('Token loaded :', savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };

        getToken();
    }, []);

    useEffect(() => {
        if (!token) return;

        EntrepriseService.getStatsByPeriod(token, "thisMonth").then((stats: any) => {
            setStats(stats);
            console.log('Stats loaded :', stats);
        });
        EntrepriseService.getCompany(token).then((data: any) => {
            const id = data._id;
            EntrepriseService.getCompanyChallenges(token, id).then((challenges: any[]) => {
                console.log('Challenges:', challenges);
                setChallenge(challenges);
                setLoading(false);
            }).catch((error: any) => {
                console.error('Error fetching challenges:', error);
            });
        }
        );
    }, [token, refresh]);

    const handleLinkClick = (index: any) => {
        setSelectedLink(index);

        let period = ((index === 0) ? 'thisMonth' : (index === 1) ? 'thisWeek' :(index === 2) ? 'today' : 'all');

        EntrepriseService.getStatsByPeriod(token, period).then((stats: any) => {
            setStats(stats);
            console.log('Stats loaded :', stats);
        });
    };

    const ChallengesCarrousel = challenge.map((challengeItem, index) => (
        <div className='card-challenge-container' key={index}>
            <img src={`${BASE_URL}/ressources/challenges/${challengeItem.local.fr.picture}`} alt="" className='background-card-challenge' />
            <h3>{challengeItem.local.fr.name}</h3>
            <div className='info-card-challenge-container'>
                <p className='nombre-inscrit-challenge'>{challengeItem.registred} {t("inscrits")}</p>
                <div className='date-card-challenge-container'>
                    <FontAwesomeIcon icon={faClock} />
                    <p>{challengeItem.dayLeft} {challengeItem.dayLeft !== 1 ? t("jours-restants") : t("jour-restant-singulier")}</p>
                </div>
            </div>
        </div>
    ));

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 2 },
        1444: { items: 3 },
    };

    if (loading) return (<div className="container-loading"><PropagateLoader color="#4F12B8" /></div>);
    const handleRefresh = () => {   
        setRefresh(!refresh);
    }

    return (
        <div className='container'>
            <Header title='Challenge'></Header>

            <div className='stats-date-container item-container'>
                <div className='stat-date-title'>
                    <h3>{t("stats-periode")}</h3>
                </div>
                <div className='left-date-link-container'>
                    <div className={selectedLink === 0 || 1 || 2 ? "date-link-container-selected" : 'date-link-container'}>
                        <a href="#" onClick={() => handleLinkClick(0)} className={selectedLink === 0 ? 'selected-link' : 'link'}>{t("ce-mois")}</a>
                        <a href="#" onClick={() => handleLinkClick(1)} className={selectedLink === 1 ? 'selected-link' : 'link'}>{t("cette-semaine")}</a>
                        <a href="#" onClick={() => handleLinkClick(2)} className={selectedLink === 2 ? 'selected-link' : 'link'}>{t("aujourdhui")}</a>
                        <a href="#" onClick={() => handleLinkClick(3)} className={selectedLink === 3 ? 'selected-link' : 'link'}>{t("tous")}</a>
                    </div>
                    <a onClick={handleRefresh} style={{cursor:"pointer"}}>
                        <div className='refresh-icon'>
                            <FontAwesomeIcon icon={faArrowsRotate} size='xl' className='ellipse-white-top' />
                        </div>
                    </a>
                </div>
            </div>

            <div className='content-top item-container'>
                <div className='all-card-container '>

                    <div className='card-container'>
                        <div className='card-logo-container'>
                            <FontAwesomeIcon icon={faPenField} size='2xl' />
                            {/* <div className='stats-up-container'>
                                <FontAwesomeIcon icon={faArrowUpRight} />
                                <p>+3%</p>
                            </div> */}
                        </div>
                        <div className='title-card'>
                            <h3>{stats?.registredCount || 0}</h3>
                        </div>
                        <div className='desc-card'>
                            <p>{t("nb-inscrits")}</p>
                        </div>
                    </div>

                    <div className='card-container'>
                        <div className='card-logo-container'>
                            <FontAwesomeIcon icon={faAward} size='2xl' />
                            {/* <div className='stats-up-container'>
                                <FontAwesomeIcon icon={faArrowUpRight} />
                                <p>+3%</p>
                            </div> */}
                        </div>
                        <div className='title-card'>
                            <h3>{stats?.registeredUsersDonePercentage || 0} %</h3>
                        </div>
                        <div className='desc-card'>
                            <p>{t("challenge-complete")}</p>
                        </div>
                    </div>

                    <div className='card-container'>
                        <div className='card-logo-container'>
                            <FontAwesomeIcon icon={faFlag} size='2xl' />
                            {/* <div className='stats-down-container'>
                                <FontAwesomeIcon icon={faArrowDownRight} />
                                <p>-3%</p>
                            </div> */}
                        </div>
                        <div className='title-card'>
                            <h3>{stats?.registredUsersPercentage || 0} %</h3>
                        </div>
                        <div className='desc-card'>
                            <p>{t("participation-challenges")}</p>
                        </div>

                    </div>

                    {/* <div className='card-container'>
                        <div className='card-logo-container'>
                            <FontAwesomeIcon icon={faBullseyeArrow} size='2xl' />
                            <div className='stats-up-container'>
                                <FontAwesomeIcon icon={faArrowUpRight} />
                                <p>+3%</p>
                            </div> 
                        </div>
                        <div className='title-card'>
                            <h3>78 %</h3>
                        </div>
                        <div className='desc-card'>
                            <p>Taux de participation à au moins un défi</p>
                        </div>
                    </div>*/}
                </div>

                {/* <div className='container-chart'>
                    <InterpolationModesChart
                        title="Nombre de connexions journalières - Février 2024"
                        labels={labels}
                        width="50%"
                        height="400px"
                    />
                </div> */}

            </div>

            <br />

            <div className='item-container'>
                <h2>{t("challenge-en-cours")}</h2>
                <div className=''>
                    <AliceCarousel
                        items={ChallengesCarrousel}
                        responsive={responsive}
                    />
                </div>
            </div>

        </div>
    );
};

export default ChallengeScreen;
