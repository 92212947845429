import React, { useEffect, useState } from 'react';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faEnvelope, faUser } from '@fortawesome/pro-regular-svg-icons';
import registerLogo from '../assets/LogoTeamupp.png';

let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const LoginScreen: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { getItem, setItem } = useAsyncStorage('token');

    useEffect(() => {
        const getToken = async () => {
            try {
                const savedToken = await getItem();
                if (savedToken !== null) {
                    await setItem(savedToken);
                }
            } catch (error) {
                console.error('Error loading token from AsyncStorage:', error);
            }
        };
        getToken();
    }, [getItem, setItem]);

    const handleSignIn = async () => {
        try {
            setError('');
            const response = await fetch(`${BASE_URL}/api/customers/signin/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Identifiants incorrects ou mot de passe invalide');
                }
                throw new Error('Failed to sign in');
            }

            const data = await response.json();
            if (!data.token) {
                throw new Error('No token received');
            }

            await setItem(data.token);
            window.location.href = '/';
        } catch (error:any) {
            console.error('Error signing in:', error);
            setError(error.message || 'Failed to sign in. Please check your credentials.');
        }
    };

    return (
        <div className='container-register' style={{ paddingBottom: "100%" }}>
            <div className='Form'>
                <div className='form-container'>
                    <div className='register-form'>
                        <img src={registerLogo} className='register-img' alt="logo" />

                        <h2>Se connecter</h2>
                        <div className='register-item'>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <input
                                className='input-register'
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                placeholder='Votre Mail'
                                value={email}
                            />
                            {error && <p className='error-message'>{error}</p>}
                        </div>

                        <div className='register-item'>
                            <FontAwesomeIcon icon={faUser} />
                            <input
                                className='input-register'
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                placeholder='Votre Mot de passe'
                                value={password}
                            />
                            {error && <p className='error-message'>{error}</p>}
                        </div>

                        <button className='register-button' onClick={handleSignIn} type='button'>Se connecter</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
