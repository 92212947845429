import React, { useState } from 'react';
import PaymentMethods from './PaymentMethod';
import BuyScreen from './BuyScreen';
import CustomerForm from './CustomerFormScreen';
import '../Tabs.css'; // Pour ajouter des styles spécifiques aux onglets

const PayInvoice = () => {
  const [activeTab, setActiveTab] = useState('paymentMethods');

  const renderContent = () => {
    switch (activeTab) {
      case 'paymentMethods':
        return <PaymentMethods />;
      case 'payments':
        return <BuyScreen />;
      case 'billingAddress':
        return <CustomerForm />;
      default:
        return <PaymentMethods />;
    }
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        <button
          className={activeTab === 'paymentMethods' ? 'active' : ''}
          onClick={() => setActiveTab('paymentMethods')}
        >
          Moyens de paiement
        </button>
        <button
          className={activeTab === 'payments' ? 'active' : ''}
          onClick={() => setActiveTab('payments')}
        >
          Paiements
        </button>
        <button
          className={activeTab === 'billingAddress' ? 'active' : ''}
          onClick={() => setActiveTab('billingAddress')}
        >
          Adresse de facturation
        </button>
      </div>
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default PayInvoice;
