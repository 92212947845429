import axios from "axios";
let BASE_URL: any;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

const EntrepriseService = {
    async getCompany(token: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/companies/company/byUser`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    

    async checkPendingPayments(token: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/companies/company/payments/inProgress`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },


    async getCompanyUsers(token: string, companyId: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/companies/company/${companyId}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async getCompanyTeams(token: string, companyId: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/companies/company/${companyId}/teams`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async getCompanyChallenges(token: string, companyId: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/companies/company/${companyId}/activeAndIncomingChallenge`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async getCompanyAgendas(token: any, companyId: any) {
        try {
            const response = await axios.get(`${BASE_URL}/api/companies/company/${companyId}/agendas`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching agenda:', error);
        }
    },

    async getCompanyRanking(token: string, rankType: string, period: string, calculMethod: string, agendaId: string, agendaType: string) {
        console.log('getCompanyRanking', token, rankType, period, calculMethod, agendaId, agendaType);

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        try {
            const response = await axios.get(`${BASE_URL}/api/ranks/rank/${rankType}/${period}/${calculMethod}/${agendaId}/${agendaType}`, config);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    async getStatsByPeriod(token: string, period: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/agendaEntries/agendaEntry/stats/${period}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agendaEntries:', error);
        }
    },

    async getActivitiesStats(token: string, period: string) {
        try {
            const response = await axios.get(`${BASE_URL}/api/activities/stats/${period}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            return response.data;
        } catch (error) {
            console.error('Error fetching agendaEntries:', error);
        }
    }
};

export default EntrepriseService;
